.photo-change-view-status {
  position: absolute;
  z-index: 20;
  right: 0;
  left: 0;
  text-align: center;
}

.photo-change-view-status select {
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #ffffff;
  font-size: 0.8em;
  height: 35px;
  line-height: 1;
  padding: 1px 0 0 12px;
  width: 100%;
}

.photo-change-view-status svg {
  position: absolute;
  top: 8px;
  right: 6px;
  fill: #ffffff;
  z-index: -1;
}
