.button {
  font-weight: 600;
  position: relative;
  background: linear-gradient(to bottom, #7e5ce4 50%, #7054c5 100%);
  border: 0;
  padding: 0 0.8em;
  border-radius: 3px;
  color: #ffffff;
  font-size: em(14);
  cursor: pointer;
  outline: none;
  white-space: nowrap;
}

.button .tooltip {
  line-height: 1.4;
  padding: 5px 10px 6px 10px;
}

.button .icon {
  position: absolute;
  right: 5px;
  top: 3px;
}

.button.-is-view-landing-page {
  margin-left: auto;
}

.button.-only-icon {
  /* The icon is positioned absolutely so we need to specify dimensions */
  height: 31px;
  width: 31px;
}

.button.-only-icon .icon {
  right: 3px;
  top: 5px;
}

.button.-is-large {
  padding: 1em 4em 1.1em 4em;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.button:hover {
  background: linear-gradient(to bottom, #6e47e1 50%, #5c31dd 100%);
}

.button:disabled {
  opacity: 0.5;
}

.button .sub-text {
  font-weight: 400;
  font-size: 0.9em;
  margin: 0.65em 0 0 0;
  font-style: italic;
  line-height: 1.3;
}

/* === Begin Global Card Switcher icon === */

.button.-is-global-view-switcher,
.button.-is-stats-viewer {
  font-size: 0.9rem;
  padding: 6px 11px;
  border: 1px solid #d5d7dc;
  border-radius: 3px;
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 0%, #f2f4f7 100%);
  color: #495764;
  align-items: center;
  display: flex;

  align-self: flex-end;
  font-size: 0.75em;
  justify-content: center;
  max-width: 190px;
  height: 29px;
  padding: 0 30px 0 8px;
}
.button.-is-global-view-switcher:hover,
.button.-is-stats-viewer:hover {
  color: #ffffff;
}

.button.-is-global-view-switcher:hover svg,
.button.-is-stats-viewer:hover svg {
  fill: #ffffff;
}

.button.-is-global-view-switcher {
  margin-left: auto;
}
.button.-is-global-view-switcher svg {
  width: 15px;
}

.button.-is-global-view-switcher .icon {
  margin-left: 7px !important;
}

/* === End Global Card Switcher icon === */

.button.-is-no-stats {
  margin: 0 auto;
  padding: 12px 17px;
  font-size: 16px;
  width: 100%;
}

.button.-is-working .text {
  display: inline-block;
  padding-top: 5px;
}
.button.-is-working .icon {
  animation: spin 1s infinite linear;
  line-height: 1;
}

.button.-is-submit {
  padding: 1em 0;
  width: 100%;
}

.button.-is-minimal {
  padding: 0;
  border: none;
  background: none;
  font-weight: normal;
  font-size: 0.8em;
  color: #adbdcb;
}

.button.-is-minimal:hover {
  background: none;
  text-decoration: underline;
}

.button.-is-add-subscription-coupon {
  position: absolute;
  bottom: 0;
  right: 0;
}

.button.-is-report-form {
  border-radius: 0;
  margin: 0;
}

.button.-is-add-first-link {
  margin-top: 1em;
  padding: 7px 15px 8px 15px;
}

.button.-is-load-more-photos-inline-button {
  padding: 0;
  background: none;
  color: #7758d5;
  margin: 4px 0 0 0;
}

.button.-is-load-more-photos-inline-button:hover {
  background: none;
}

.button.-is-load-more-photos-inline-button .icon {
  height: 13px;
  width: 13px;
  left: 4px;
  top: -1px;
}
