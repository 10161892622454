/* ---- Main Card Swiper ---- */

.swiper-cards-container > .swiper-pagination-bullets {
  display: flex;
  height: 16px;
  align-items: center;
  top: 1px;
  width: calc(100% - 20px);
  left: 10px;
  bottom: initial;
}

.swiper-cards-container > .swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: transparent;
  flex: 1;
  border-radius: 0px;
  padding: 0;
  margin: 0 4px;
  opacity: 1;
}

.swiper-cards-container > .swiper-pagination-bullets .swiper-pagination-bullet > .progress {
  background-color: rgba(255, 255, 255, 0.32);
  border-radius: 2px;
  height: 2px;
}

.swiper-cards-container > .swiper-pagination-bullets .swiper-pagination-bullet-active > .progress {
  background-color: white;
}

/* ---- Filter Selector Swiper ---- */

.swiper-background-filters-container > .swiper-pagination-bullets {
  bottom: 20px;
}

.swiper-background-filters-container > .swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: white;
}
