.label .label-text {
  color: #adbdcb;
  white-space: nowrap;
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  line-height: 1.1;
}

.label.-is-checkbox {
  display: flex;
  align-items: center;
}

.label.-is-checkbox .label-text {
  margin: 0 10px 0 0;
  line-height: 1;
}

.label.-full-border {
  border: 1px solid #e6eaee;
}

label.label.-without-top-border {
  border-top: none;
}
