.photo-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e6eaee;
  position: relative;
  margin: 0.375rem 0;
  z-index: 10;
}

.photo-card.-is-pinned {
  background-color: #fffcf0;
}
.photo-card.-is-pinned .field,
.photo-card.-is-pinned .textarea {
  background-color: #fffcf0;
}

.photo-card.-is-pinned .field:first-child:after,
.photo-card.-is-pinned .field:first-child.-is-focused:after {
  background: linear-gradient(
    to right,
    red 0%,
    rgba(255, 249, 228, 0) 0%,
    rgba(255, 252, 239, 1) 100%
  );
}

.photo-card .photo-container {
  position: relative;
}

.photo-card.-is-hidden .blocker {
  display: block;
  position: absolute;
  z-index: 10;
  left: 33.3333%;
  left: -1px;
  top: 0px;
  right: -1px;
  bottom: 0px;
  background-color: #ffffff;
  opacity: 0.7;
}

.photo-card .blocker {
  display: none;
}

.photo-card .photo-actions {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);
  bottom: auto !important;
  display: flex;
  font-size: 1.2em;
  height: 44px;
  left: -1px;
  padding-left: 0.35em !important;
  position: absolute;
  right: 0;
  top: -1px;
}

.photo-card .actions-panel {
  width: 100%;
  display: flex;
}

.photo-card .edit-panel {
  height: 100%;
  width: 100%; /* Why? */
}

.photo-card .no-stats {
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%; /* Why? */
  padding: 1rem 0.75rem 1rem 0.75rem;
  text-align: center;
  justify-content: center;
  line-height: 1.25;
  color: #aab3bb;
  font-style: italic;
  font-size: 0.9em;
}
