.photo-form {
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.photo-form .fieldset {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.photo-form .fieldset .field:first-child {
  padding-right: 0;
}

.photo-form .actions {
  padding: 10px 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.photo-form .actions .clear {
  background: linear-gradient(to bottom, #f05852 50%, #ec2a23 100%);
  margin-right: auto;
}

.photo-form .actions .save {
  margin-left: auto;
}

.photo-form .actions .clear,
.photo-form .actions .save {
  padding: 10px 15px;
  font-size: 14px;
}

.photo-form .saved {
  margin-left: auto;
  color: #55bb3e;
}

.photo-form .url-field {
  display: flex;
}

.photo-form .url-field .field {
  flex-grow: 1;
}
