.instagram-media-item-meta {
  padding: 8px 0 10px 0;
  border-bottom: 1px solid #e6eaee;
}
.instagram-media-item-meta .instagram-meta {
  color: #929ea8;
  display: flex;
  font-weight: 400;
  justify-content: space-around;
}

.instagram-media-item-meta .instagram-meta .instagram-meta-element {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.instagram-media-item-meta .instagram-logo {
  color: #929ea8;
  margin-top: 0.1em;
}

.instagram-media-item-meta .instagram-logo svg {
  vertical-align: middle;
}

.instagram-media-item-meta .instagram-logo svg path {
  fill: #7f8d99;
}

.instagram-media-item-meta .count,
.instagram-media-item-meta .date {
  display: inline-block;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;
}
