.photo-cards {
  display: flex;
  flex-direction: column;
}

.photo-cards .global-actions {
  display: flex;
  margin: 0.7em 0;
}

.photo-cards .photo-count {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #e6eaee;
  color: #354052;
  display: flex;
  font-size: 0.7rem;
  justify-content: center;
  line-height: 1;
  margin-bottom: 5px;
  padding: 8px 0 7px 0;
  flex-direction: column;
}

.photo-cards .count {
  margin: 0 0 0 3px;
}

.photo-cards-container {
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  justify-content: flex-end;
  padding: 0.375em 0 0.75em 0;
}

.force-refresh-button {
  background-color: #eceff1;
  background-image: linear-gradient(to top, #eceff1, #fff);
  border-radius: 0.5em;
  border: 1px solid #dddddd;

  padding: 5px 10px 5px 9px;
  cursor: pointer;
}

.force-refresh-button > svg {
  margin-left: 5px;
}

.force-refresh-button:hover {
  background-image: linear-gradient(to top, #dcdedf, #fff);
}
