.posts-panel {
  height: auto;
  overflow: auto;
  padding: 1.5rem 10px;
  background-color: white;
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 0;
  padding-bottom: 70px;

  /*  Smooth Scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.posts-panel::-webkit-scrollbar {
  /* Hide scrollbars */
  width: 0;
  height: 0;
}
