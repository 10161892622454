* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-image: linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)),
    linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  background-blend-mode: multiply, screen;
  background-color: rgba(204, 204, 204, 0.5);
  border-radius: 5px;
}

html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu;
}

a {
  text-decoration: none;
}

a:active {
  opacity: 0.75;
}

svg,
img {
  max-width: 100%;
}
