.field {
  position: relative;
  display: block;
  padding: 0.5rem 0.8rem;
  border-bottom: 1px solid #e6eaee;
  background-color: #fff;
}
.field.-is-focused {
  background-color: #fff0b9;
}

.field input {
  background: none;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  border: 0;
  outline: 0;
  padding: 0;
  color: #354052;
  border-radius: 0;
  display: block;
}

.field input::placeholder {
  color: #e3e6e8;
  font-style: italic;
}

.field.-has-error .label-text {
  color: #f05852;
}

.field.-is-coupon-on-billing-form {
  border: 1px solid #e6eaee;
  margin-bottom: 1em;
}
