.edit-photo-details {
  position: relative;
  z-index: 5;
  height: 100%;
}

.edit-photo-details .photo {
  position: relative;
  z-index: 10;
}

.edit-photo-details .photo img {
  display: block;
  position: relative;
  left: -1px;
  top: -1px;
  width: calc(100% + 1px);
}
